
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonSpinner,
    IonIcon,
    IonButton,
    IonButtons,
    menuController,
    onIonViewWillEnter,
} from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { openToast } from "../services/toast";

import apiBancaOre from "../services/banca_ore";

import { dateFormat } from "@/services/utils";

export default {
    name: "BancaOre",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
    },
    setup() {
        const router = useRouter();
        const loading = ref(false);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).users_id;
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const banca_ore = ref([]);
        const saldo = ref(0);
        const data_aggiornamento = ref("-");

        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         * Get all banca ora records
         */
        async function loadBancaOre() {
            loading.value = true;
            try {
                banca_ore.value = await apiBancaOre.getOre(dipendenteID);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle ore disponibili", "toast_danger");
            }
            loading.value = false;
        }

        async function loadOre() {
            loading.value = true;
            try {
                const res = await apiBancaOre.getBancaOre(dipendenteID);

                if (res.data.status === 1) {
                    openToast(res.data.txt, "toast_danger");
                } else if (res.data.status === 0) {
                    banca_ore.value = res.data.data;
                    saldo.value = res.data.saldo;
                    data_aggiornamento.value = res.data.data_aggiornamento ? res.data.data_aggiornamento : "-";
                } else {
                    openToast("Errore durante la richiesta delle ore disponibili", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta delle ore disponibili", "toast_danger");
            } finally {
                loading.value = false;
            }
        }
        loadOre();

        //Set correct background for tipo movimento
        const tipoMovimento = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //Aggiunta
                    className = "badge_aggiunta";
                } else if (statusId == 2) {
                    //Cancellata
                    className = "badge_cancellata";
                } else if (statusId == 3) {
                    //Pagata
                    className = "badge_pagata";
                } else if (statusId == 4) {
                    //Utilizzata
                    className = "badge_utilizzata";
                }
                return className;
            };
        });

        /**
         * ! Calculate banca ore balance
         */
        const calcolaSaldo = computed(() => {
            let balance = 0;
            banca_ore.value.forEach((element) => {
                //onsole.log(element);
                switch (element.banca_ore_movimento) {
                    case "1": //Aggiunto
                        balance += parseFloat(element.banca_ore_hours);
                        break;
                    case "2": ///Cancellata
                        balance += 0;
                        break;
                    case "3": //Pagata
                    case "4": //Utilizzata
                        balance -= parseFloat(element.banca_ore_hours);
                        break;
                    default:
                        balance = 0;
                        break;
                }
            });

            return balance.toFixed(2);
        });

        /**
         * Return '-' if a customer field is null
         */
        function checkField(field) {
            if (field == null) {
                return " ";
            } else {
                return " - " + field;
            }
        }

        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }

        /* Ad ogni accesso alla pagina richiedo i dati della bbanca ore perchè potrebbe essere cambiato il saldo */
        onIonViewWillEnter(() => {
            //loadBancaOre();
            loadOre();
        });

        return {
            loading,
            dateFormat,
            add,
            refresh,
            checkField,
            //New field
            openMenu,
            menu,
            loadOre,
            banca_ore,
            tipoMovimento,
            //calcolaSaldo,
            saldo,
            data_aggiornamento,
        };
    },
};
